'use client';

export interface EIP6963ProviderDetail {
  info: EIP6963ProviderInfo;
  provider: EIP1193Provider;
}

export interface EIP6963ProviderInfo {
  walletId: string;
  uuid: string;
  name: string;
  icon: string;
}

export interface EIP1193Provider {
  isStatus?: boolean;
  host?: string;
  path?: string;
  sendAsync?: (
    request: { method: string, params?: Array<unknown> },
    callback: (error: Error | null, response: unknown) => void
  ) => void
  send?: (
    request: { method: string, params?: Array<unknown> },
    callback: (error: Error | null, response: unknown) => void
  ) => void
  request: (request: { method: string, params?: Array<unknown> }) => Promise<unknown>
}

export type EIP6963AnnounceProviderEvent = {
  detail:{
    info: EIP6963ProviderInfo,
    provider: EIP1193Provider
  }
}

export const providers: Map<string, EIP6963ProviderDetail> = new Map();

export const storeProviders = {
  value: () => providers,
  subscribe: (callback: () => void) => {
    const onAnnouncement = (event: EIP6963AnnounceProviderEvent) => {
      const { uuid } = event.detail.info || {};
      if (!uuid) return;
      if (providers.get(uuid)) return;
      providers.set(uuid, event.detail);
      callback();
    };
    window.addEventListener('eip6963:announceProvider', onAnnouncement);
    window.dispatchEvent(new Event('eip6963:requestProvider'));
    return () => window.removeEventListener('eip6963:announceProvider', onAnnouncement);
  },
};