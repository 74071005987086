import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query';
import { Mutex } from 'async-mutex';
import { refreshQuery } from 'connectors/secretkeeper';
import { setUser, resetState, setAccessToken } from '.';
import { User } from './types';

const mutex = new Mutex();

type BaseQuery = BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>;

export const baseQueryWithRefresh = (
  baseQuery: BaseQuery,
): BaseQuery => async (args, api, extraOptions) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);
  if (result?.error?.status === 401) {
    // checking whether the mutex is locked
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const refreshResult = await refreshQuery(
          '/auth/refresh-access',
          api,
          extraOptions,
        );
        if (refreshResult?.data) {
          const { user, accessToken } = refreshResult.data as { user: User; accessToken: string } || {};
          api.dispatch(setUser(user || null));
          api.dispatch(setAccessToken(accessToken || null));
          // retry the initial query
          result = await baseQuery(args, api, extraOptions);
        } else {
          api.dispatch(resetState());
        }
      } finally {
        // release must be called once the mutex should be released again.
        release();
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};