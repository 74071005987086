import { createAsyncThunk, Dispatch } from '@reduxjs/toolkit';
import { trackEvent } from 'lib/features/events/thunks';
import { MetaMask } from '@web3-react/metamask';
import {
  WalletState, Balance, ChangeWalletArgs, UpdateBalanceArgs,
} from './types';
import { getInitialBalance, getConnectFunc, updateSelectedProvider } from './helpers';
import { Providers } from '../secretKeeper/types';

export const updateBalance = createAsyncThunk<
  Balance,
  UpdateBalanceArgs,
  { state: { wallet: WalletState } }
>(
  'wallet/updateBalance',
  async ({ getBalance }: UpdateBalanceArgs, { getState }) => {
    const { selectedAddress } = getState()?.wallet || {};
    if (!selectedAddress) return getInitialBalance();
    return getBalance(selectedAddress);
  },
  {
    condition: (_, { getState }) => {
      const { loadingBalance, selectedWalletType, selectedAddress } = getState()?.wallet || {};
      if (loadingBalance || !selectedAddress || !selectedWalletType) return false;
    },
  },
);

let connector: MetaMask | null | undefined;

export const logoutWallet = createAsyncThunk<
  void,
  void,
  { state: { wallet: WalletState }, dispatch: Dispatch<any> }
  >(
    'wallet/logoutWallet',
    async () => {
      if (connector) {
        connector?.deactivate?.();
        connector?.resetState?.();
        connector = null;
      }
    },
  );

export const changeWallet = createAsyncThunk<
  void,
  ChangeWalletArgs,
  { state: { wallet: WalletState }, dispatch: Dispatch<any> }
>(
  'wallet/changeWallet',
  async ({
    walletType, actions, onSuccess, onError, isSendMetrics = false,
  }: ChangeWalletArgs, { dispatch, getState }) => {
    const eventType = 'user_authorized';
    const { selectedAddress } = getState()?.wallet || {};
    try {
      if (!walletType) return;
      await updateSelectedProvider(walletType);
      connector = await getConnectFunc(walletType)?.(actions);
      if (isSendMetrics && selectedAddress) {
        dispatch(trackEvent({
          eventType,
          property: {
            result: 'success',
            provider: Providers.metamask,
            userId: selectedAddress,
          },
        }));
      }
      onSuccess?.();
    } catch (e) {
      if (isSendMetrics) {
        let error = 'process error';
        if (typeof e === 'string') {
          error = e;
        } else if (typeof e === 'object') {
          error = (e as Error)?.message || (Object.keys(e as Error).length ? JSON.stringify(e) : error);
        }
        dispatch(trackEvent({
          eventType,
          property: {
            result: 'error',
            error,
            errorStack: (e as Error)?.stack,
          },
        }));
      }
      onError?.(e as Error);
      throw e;
    }
  },
  {
    condition: (_, { getState }) => {
      const { loadingBalance, loadingWallet } = getState()?.wallet || {};
      if (loadingBalance || loadingWallet) return false;
    },
  },
);
