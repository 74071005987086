import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModalsState, ModalType } from './types';

const initialState: ModalsState = {
  isOpened: {},
};

export const modals = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    openModal(state, action: PayloadAction<{ type: ModalType }>) {
      const { type } = action.payload;
      state.isOpened = { ...state.isOpened, [type]: true };
    },
    closeModal(state, action: PayloadAction<{ type: ModalType }>) {
      const { type } = action.payload;
      state.isOpened = Object.entries(state.isOpened).reduce((acc, [k, v]) => (
        k !== type ? { ...acc, [k]: v } : acc
      ), {});
    },
  },
  selectors: {
    isOpenedModalsSelector: (state) => (state.isOpened),
  },
});

export const {
  openModal,
  closeModal,
} = modals.actions;

export const {
  isOpenedModalsSelector,
} = modals.selectors;
