export interface User {
  id: string;
  createdAt: string;
  updatedAt: string;
  email?: string;
  username?: string;
  name?: string;
  address?: string;
  nonce?: string;
}

export enum Providers {
  microsoft = 'microsoft',
  google = 'google',
  facebook = 'facebook',
  github = 'github',
  huggingface = 'huggingface',
}

export interface Provider {
  provider: Providers;
  createdAt: string;
  updatedAt: string;
}

export interface AccessToken { provider?: Providers; id?: string }

export interface AuthOAuth2State {
  user?: User | null;
  accessToken?: string | null;
  providers?: Provider[] | null;
  loadingAuth: boolean;
}

export interface TokenResponse { user: User, accessToken: string }