import { Theme } from 'uikitv2/common/types';

export enum Modes {
  own = 'own',
  generate = 'generate',
}

export interface MnemonicGeneratorUiProps {
  value?: string;
  onChange?: Function;
  isInvalid?: boolean;
  error?: string;
  mode?: Modes;
  setMode?: Function;
  phrase?: string;
  onGenerate?: () => void;
  theme?: Theme;
  onCopy?: (text: string) => void;
}
