import {
  fetchBaseQuery,
  BaseQueryApi,
} from '@reduxjs/toolkit/query';
import { getAuthorizationHeader } from 'utils';
import { SECRET_KEEPER_API } from './urls';

export const prepareHeaders = (
  headers: Headers,
  api: Pick<BaseQueryApi, 'getState' | 'extra' | 'endpoint' | 'type' | 'forced'>,
) => {
  const { getState } = api;
  const { accessToken } = (getState() as any).secretKeeper || {};
  if (accessToken) {
    headers.set('Authorization', getAuthorizationHeader(accessToken));
  }
  return headers;
};

const baseUrl = `${SECRET_KEEPER_API}/api`;

export const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders,
});

export const refreshQuery = fetchBaseQuery({
  baseUrl,
  method: 'POST',
  credentials: 'include',
});