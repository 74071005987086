import { createAsyncThunk } from '@reduxjs/toolkit';
import { createSignature } from 'lib/features/wallet/utils/web3Provider';
import { addErrorNotification } from 'lib/features/notifications';
import { validateRTKResponse } from 'lib/features/helpers';
import { parseJwt } from 'utils';
import { setLoadingAuthWallet, resetState } from '.';
import { AccessToken } from './types';
import { api as AuthApi } from './api';

// // eslint-disable-next-line import/no-cycle
// import { RootState } from 'lib/store'; // todo

// prevent run authByWallet, if condition does not have actual state
let isAuthByWalletRunning = false;

export const authByWallet = createAsyncThunk<
  void,
  string | null | undefined,
  {
    rejectValue: string | Error,
    state: any,
  }>(
    'auth/wallet',
    async (address, { getState, rejectWithValue, dispatch }) => {
      if (isAuthByWalletRunning) {
        return;
      }
      isAuthByWalletRunning = true;
      try {
        const state = getState();
        dispatch(resetState({ loadingAuthWallet: true }));
        const { selectedWalletType } = state.wallet;
        const nonceResponse = validateRTKResponse(
          await dispatch(AuthApi.endpoints.getNonce.initiate(address as string, { forceRefetch: true })),
          { path: 'message', skipStatuses: [404] },
        );
        let nonce = nonceResponse?.data?.nonce;
        if (!nonce) {
          const signUpResponse = validateRTKResponse(
            await dispatch(AuthApi.endpoints.signUp.initiate({ address: address as string })),
            { path: 'message' },
          );
          nonce = signUpResponse.data?.nonce;
        }
        if (!nonce) throw new Error('Nonce required');
        if (!address) throw new Error('Wallet address required');
        const signature = await createSignature(selectedWalletType, nonce, address);
        validateRTKResponse(
          await dispatch(AuthApi.endpoints.updateToken.initiate({ address, signature })),
          { path: 'message' },
        );
      } catch (err) {
        const errorMessage = (err as Error)?.message || 'Auth wallet error';
        dispatch(addErrorNotification(errorMessage));
        return rejectWithValue(errorMessage);
      } finally {
        isAuthByWalletRunning = false;
        dispatch(setLoadingAuthWallet(false));
      }
    },
    {
      condition: async (address, { getState }) => {
        const state = getState();
        const { accessToken, loadingAuthWallet } = state.secretKeeper;
        if (loadingAuthWallet || !address) return false;
        const accessTokenParsed = parseJwt<AccessToken>(accessToken);
        const addressFromAccessToken = accessTokenParsed?.address;
        if (!addressFromAccessToken || addressFromAccessToken !== address) {
          return true;
        }
        return false;
      },
    },
  );