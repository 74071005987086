import { createSelector } from '@reduxjs/toolkit';
import { BigNumber } from 'bignumber.js';
import { Balance } from './types';
import { balanceSelector } from '.';

export const balanceBigNumberSelector = createSelector(
  balanceSelector,
  ({ tee }: Balance = {}) => ({
    tee: typeof tee === 'string' ? new BigNumber(tee || '0') : undefined,
  }),
);