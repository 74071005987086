import { useCallback } from 'react';
import { changeWallet } from 'lib/features/wallet/thunks';
import { getAction } from 'lib/features/wallet';
import { ChangeWalletArgs, GetActionProps } from 'lib/features/wallet/types';
import { useAppDispatch } from 'lib/hooks';

export interface ConnectWalletProps extends Omit<ChangeWalletArgs, 'actions'> {}

const useWalletConnector = () => {
  const dispatch = useAppDispatch();

  const connectWallet = useCallback((props: ConnectWalletProps, actionProps?: Partial<GetActionProps>) => {
    return dispatch(changeWallet({ ...props, actions: getAction({ ...props, dispatch, ...actionProps }) }));
  }, [dispatch]);

  return {
    connectWallet,
  };
};

export default useWalletConnector;