import * as Types from '../types';

import { api } from '../../connectors/sp';
export type PageDataDtoFragmentFragment = { __typename?: 'PageDataDto', count: number, limit: number, offset: number };

export type ProvidersQueryVariables = Types.Exact<{
  pagination: Types.ConnectionArgs;
  filter: Types.ProviderFilter;
}>;


export type ProvidersQuery = { __typename?: 'Query', result: { __typename?: 'ListProvidersResponse', pageData?: { __typename?: 'PageDataDto', count: number, limit: number, offset: number } | null, page: { __typename?: 'ProviderConnection', pageInfo?: { __typename?: 'ProviderPageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null } | null, edges?: Array<{ __typename?: 'ProviderEdge', cursor?: string | null, node?: { __typename?: 'Provider', _id: string, address: string, providerInfo: { __typename?: 'ProviderInfo', name: string, actionAccount: string } } | null }> | null } } };

export const PageDataDtoFragmentFragmentDoc = `
    fragment PageDataDtoFragment on PageDataDto {
  count
  limit
  offset
}
    `;
export const ProvidersDocument = `
    query Providers($pagination: ConnectionArgs!, $filter: ProviderFilter!) {
  result: providers(pagination: $pagination, filter: $filter) {
    pageData {
      ...PageDataDtoFragment
    }
    page {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        node {
          _id
          address
          providerInfo {
            name
            actionAccount
          }
        }
        cursor
      }
    }
  }
}
    ${PageDataDtoFragmentFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    Providers: build.query<ProvidersQuery, ProvidersQueryVariables>({
      query: (variables) => ({ document: ProvidersDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useProvidersQuery, useLazyProvidersQuery } = injectedRtkApi;

