import { useCallback, useMemo } from 'react';
import {
  useLogoutMutation,
  api as authApi,
  useLazyUpdateMeQuery,
  useLazyGetMeQuery,
} from 'lib/features/secretKeeper/api';
import { authByWallet } from 'lib/features/secretKeeper/thunks';
import { useAppSelector, useAppDispatch } from 'lib/hooks';
import {
  providerSelector, userSelector, loadingAuthWalletSelector, accessTokenSelector, isProviderSelector, resetState,
} from 'lib/features/secretKeeper';
import useWalletConnector from 'lib/features/wallet/hooks/useWalletConnector';
import { Providers, User } from 'lib/features/secretKeeper/types';
import {
  isConnectedSelector, loadingWalletSelector,
} from 'lib/features/wallet';
import { logoutWallet } from 'lib/features/wallet/thunks';
import { WalletType } from 'types/wallet';

export interface UseAuthSecretKeeper {
  provider?: Providers | null;
  user?: User | null;
  loading: boolean;
  isUserConnected: boolean;
  isAuthChecked: boolean;
  logout: () => Promise<void>;
  login: (provider: Providers) => Promise<void>;
  isProvider: boolean;
  getIsNewProvider: (provider?: unknown) => boolean;
  updateCurrentUser: ReturnType<typeof useLazyUpdateMeQuery>[0];
  getCurrentUser: ReturnType<typeof useLazyGetMeQuery>[0];
}

export const useAuthSecretKeeper = (): UseAuthSecretKeeper => {
  const accessToken = useAppSelector(accessTokenSelector);
  const { connectWallet } = useWalletConnector();
  const dispatch = useAppDispatch();
  const user = useAppSelector(userSelector);
  const isProvider = useAppSelector(isProviderSelector);
  const loadingWallet = useAppSelector(loadingWalletSelector);
  const loadingAuthWallet = useAppSelector(loadingAuthWalletSelector);
  const provider = useAppSelector(providerSelector);
  const isConnectedWallet = useAppSelector(isConnectedSelector);
  const updateMeState = useMemo(() => authApi.endpoints.updateMe.select(null), []);
  const updateMeStateResponse = useAppSelector(updateMeState);
  const [logoutMutation, logoutReponse] = useLogoutMutation();
  const [updateCurrentUser] = useLazyUpdateMeQuery();
  const [getCurrentUser] = useLazyGetMeQuery();

  const logout = useCallback(async () => {
    await logoutMutation().catch(() => {});
    dispatch(resetState());
    dispatch(logoutWallet());
  }, [dispatch, logoutMutation]);

  const loginWallet = useCallback(async (walletType: WalletType) => {
    return connectWallet(
      {
        walletType,
        onError: (e: Error) => {
          throw e;
        },
        isSendMetrics: true,
      },
      {
        onUpdate: (address?: string) => dispatch(authByWallet(address)),
      },
    );
  }, [dispatch, connectWallet]);

  const login = useCallback(async (provider: Providers) => {
    if (provider === Providers.metamask) {
      await loginWallet(WalletType.MetaMask);
    } else {
      throw new Error('Bad provider');
    }
  }, [loginWallet]);

  const isUserConnected = useMemo(() => {
    if (!provider) return false;
    if (provider === Providers.metamask) {
      return isConnectedWallet && !!user;
    }
    return false;
  }, [isConnectedWallet, provider, user]);

  const isAuthChecked = !accessToken || updateMeStateResponse.status !== 'uninitialized';

  const loading = logoutReponse?.isLoading
   || loadingWallet
   || loadingAuthWallet
   || updateMeStateResponse?.isLoading
   || false;

  const getIsNewProvider = useCallback((newProvider?: unknown) => {
    if (!newProvider) return false;
    return Object.values(Providers).includes(newProvider as Providers);
  }, []);

  return {
    user,
    provider,
    isUserConnected,
    loading,
    isAuthChecked,
    isProvider,
    login,
    logout,
    getIsNewProvider,
    updateCurrentUser,
    getCurrentUser,
  };
};