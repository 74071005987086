import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import { parseJwt } from 'utils';
import { getBroatcastActions } from 'lib/features/helpers';
import { Storage } from '../types';
import {
  SecretKeeperState, User, AccessToken, Providers,
} from './types';
import { getInitialState } from './helpers';

const initialState: SecretKeeperState = getInitialState();

export const secretKeeper = createSlice({
  name: 'secretKeeper',
  initialState,
  reducers: {
    resetState: (state, action: PayloadAction<Partial<SecretKeeperState> | undefined>) => ({
      ...getInitialState(),
      ...action.payload,
    }),
    setUser: (state, action: PayloadAction<User | null>) => {
      state.user = action.payload;
    },
    setAccessToken: (state, action: PayloadAction<string | null>) => {
      state.accessToken = action.payload;
    },
    setLoadingAuthWallet: (state, action: PayloadAction<boolean>) => {
      state.loadingAuthWallet = action.payload;
    },
  },
  selectors: {
    userSelector: (state) => state.user,
    providerSelector: (state) => {
      const accessTokenParsed = parseJwt<AccessToken>(state.accessToken);
      if (!accessTokenParsed) return null;
      return accessTokenParsed.provider;
    },
    accessTokenSelector: (state) => state.accessToken,
    loadingAuthWalletSelector: (state) => state.loadingAuthWallet,
    accessTokenEncodedSelector: (state) => (state.accessToken ? encodeURI(state.accessToken) : null),
    accessTokenParsedSelector: (state) => (state.accessToken ? parseJwt<AccessToken>(state.accessToken) : null),
    isProviderSelector: (secretKeeper) => {
      const provider = providerSelector({ secretKeeper });
      if (!provider) return false;
      return Object.values(Providers).includes(provider);
    },
  },
});

export const {
  setUser,
  resetState,
  setAccessToken,
  setLoadingAuthWallet,
} = secretKeeper.actions;

export const {
  userSelector,
  accessTokenSelector,
  accessTokenEncodedSelector,
  providerSelector,
  loadingAuthWalletSelector,
  accessTokenParsedSelector,
  isProviderSelector,
} = secretKeeper.selectors;

export const getReducer = (storage: Storage) => persistReducer({
  key: 'secretKeeper', storage, whitelist: ['accessToken'],
}, secretKeeper.reducer);

export const getBroatcastWhiteList = () => getBroatcastActions(secretKeeper);