import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getBroatcastActions } from 'lib/features/helpers';
import { NotificationsState } from './types';

const initialState: NotificationsState = {
  notification: undefined,
};

export const notifications = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addSuccessNotification(state, action: PayloadAction<string>) {
      state.notification = action.payload;
      state.type = 'success';
    },
    addErrorNotification(state, action: PayloadAction<string>) {
      state.notification = action.payload;
      state.type = 'error';
    },
    removeNotification(state) {
      state.notification = undefined;
      state.type = undefined;
    },
  },
  selectors: {
    notificationsSelector: (state) => (state),
  },
});

export const {
  addSuccessNotification,
  addErrorNotification,
  removeNotification,
} = notifications.actions;

export const {
  notificationsSelector,
} = notifications.selectors;

export const getBroatcastWhiteList = () => getBroatcastActions(notifications);
