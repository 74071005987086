import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import { parseJwt } from 'utils';
import { getBroatcastActions } from 'lib/features/helpers';
import { Storage } from '../types';
import {
  AuthOAuth2State, User, Provider, AccessToken, Providers,
} from './types';
import { getInitialState } from './helpers';

const initialState: AuthOAuth2State = getInitialState();

export const authOAuth2 = createSlice({
  name: 'authOAuth2',
  initialState,
  reducers: {
    resetState: (state, action: PayloadAction<Partial<AuthOAuth2State> | undefined>) => ({
      ...getInitialState(),
      ...action.payload,
    }),
    setUser: (state, action: PayloadAction<User | null>) => {
      state.user = action.payload;
    },
    setAccessToken: (state, action: PayloadAction<string | null>) => {
      state.accessToken = action.payload;
    },
    setProviders: (state, action: PayloadAction<Provider[] | null>) => {
      state.providers = action.payload;
    },
    setLoadingAuth: (state, action: PayloadAction<boolean>) => {
      state.loadingAuth = action.payload;
    },
  },
  selectors: {
    userSelector: (state) => state.user,
    providersSelector: (state) => state.providers,
    providerSelector: (state) => {
      const accessTokenParsed = parseJwt<AccessToken>(state.accessToken);
      if (!accessTokenParsed) return null;
      return accessTokenParsed.provider || null;
    },
    accessTokenSelector: (state) => state.accessToken,
    loadingAuthSelector: (state) => state.loadingAuth,
    accessTokenEncodedSelector: (state) => (state.accessToken ? encodeURI(state.accessToken) : null),
    accessTokenParsedSelector: (state) => (state.accessToken ? parseJwt<AccessToken>(state.accessToken) : null),
    isProviderSelector: (authOAuth2) => {
      const provider = providerSelector({ authOAuth2 });
      if (!provider) return false;
      return Object.values(Providers).includes(provider);
    },
  },
});

export const {
  setUser,
  resetState,
  setAccessToken,
  setProviders,
  setLoadingAuth,
} = authOAuth2.actions;

export const {
  userSelector,
  accessTokenSelector,
  accessTokenEncodedSelector,
  providerSelector,
  loadingAuthSelector,
  providersSelector,
  accessTokenParsedSelector,
  isProviderSelector,
} = authOAuth2.selectors;

export const getReducer = (storage: Storage) => persistReducer({
  key: 'authOAuth2', storage, whitelist: ['accessToken'],
}, authOAuth2.reducer);

export const getBroatcastWhiteList = () => getBroatcastActions(authOAuth2);