import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'connectors/oAuth2';
import { User, Provider, TokenResponse } from './types';
import {
  setUser, resetState as resetStateAuth, setProviders, setAccessToken,
} from '.';
import { baseQueryWithRefresh } from './baseQueryWithRefresh';

export const api = createApi({
  reducerPath: 'authOAuth2Api',
  baseQuery: baseQueryWithRefresh(baseQuery),
  tagTypes: ['AuthOAuth2'],
  endpoints: (builder) => ({
    logout: builder.mutation<void, void>({
      query() {
        return {
          url: 'auth/logout',
          credentials: 'include',
        };
      },
      async onQueryStarted(_args, { dispatch, queryFulfilled }) {
        await queryFulfilled.catch((e) => e);
        dispatch(resetStateAuth());
      },
    }),
    signIn: builder.mutation<void, { address: string }>({
      query(body) {
        return {
          url: 'auth/sign-in',
          method: 'POST',
          body,
        };
      },
    }),
    signUp: builder.mutation<User, { address: string }>({
      query(body) {
        return {
          url: 'auth/sign-up',
          method: 'POST',
          body,
        };
      },
    }),
    updateToken: builder.mutation<TokenResponse, { address: string, signature: string }>({
      query(body) {
        return {
          url: 'auth/token',
          method: 'POST',
          body,
          credentials: 'include',
        };
      },
      async onQueryStarted(_args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setUser(data?.user));
          dispatch(setAccessToken(data?.accessToken));
        } catch (e) {
          setUser(null);
          setAccessToken(null);
        }
      },
    }),
    getMe: builder.query<User, null>({
      query() {
        return {
          url: 'auth/me',
        };
      },
    }),
    updateMe: builder.query<User, null | undefined>({
      query() {
        return {
          url: 'auth/me',
        };
      },
      async onQueryStarted(_args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setUser(data));
        } catch (e) {
          setUser(null);
        }
      },
    }),
    updateProviders: builder.query<{ data: Provider[] | null }, null | undefined>({
      query() {
        return {
          url: 'users/providers',
        };
      },
      async onQueryStarted(_args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setProviders(data?.data));
        } catch (e) {
          setUser(null);
        }
      },
    }),
  }),
});

export const {
  useUpdateMeQuery,
  useLazyUpdateMeQuery,
  useLogoutMutation,
  useUpdateProvidersQuery,
  useSignInMutation,
  useSignUpMutation,
  useLazyGetMeQuery,
} = api;