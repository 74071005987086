import {
  createTransform,
} from 'redux-persist';
import { getInitialForm } from './helpers';
import {
  Form,
  Steps,
} from './types';

// save only BUILD_ORDER step
export const transform = createTransform(
  (inboundState: Form) => {
    return {
      ...getInitialForm(),
      [Steps.BUILD_ORDER]: inboundState?.[Steps.BUILD_ORDER],
    };
  },
  (outboundState: Form) => {
    return {
      ...getInitialForm(),
      [Steps.BUILD_ORDER]: outboundState?.[Steps.BUILD_ORDER],
    };
  },
  { whitelist: ['form'] },
);