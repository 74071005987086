import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import isequalwith from 'lodash.isequalwith';
import {
  Balance,
  SpProviderState,
} from './types';
import { getInitialState } from './helpers';

const initialState: SpProviderState = getInitialState();

export const spProvider = createSlice({
  name: 'spProvider',
  initialState,
  reducers: {
    resetState: (state, action: PayloadAction<Partial<SpProviderState> | undefined>) => ({
      ...getInitialState(),
      ...action.payload,
    }),
    setBalance(state, action: PayloadAction<Balance>) {
      if (!isequalwith(state.balance, action.payload)) {
        state.balance = action.payload;
      }
    },
    setLoadingBalance(state, action: PayloadAction<boolean>) {
      state.loadingBalance = action.payload;
    },
  },
  selectors: {
    balanceSelector: (state) => state.balance,
    loadingBalanceSelector: (state) => state.loadingBalance,
  },
});

export const {
  resetState,
  setBalance,
  setLoadingBalance,
} = spProvider.actions;

export const {
  balanceSelector,
  loadingBalanceSelector,
} = spProvider.selectors;