import getConfig from 'config';

const {
  NEXT_PUBLIC_BFF_API,
  NEXT_PUBLIC_PROXY,
  NEXT_PUBLIC_AUTH_API,
  NEXT_PUBLIC_SECRET_KEEPER_API,
  NEXT_PUBLIC_SP_PROVIDER_API,
  NEXT_PUBLIC_PCCS_API,
} = getConfig();

export const BFF_API = NEXT_PUBLIC_PROXY.bff ? '/api/proxy-bff' : NEXT_PUBLIC_BFF_API;
export const AUTH_API = NEXT_PUBLIC_PROXY.oauth ? '/api/proxy-oauth' : NEXT_PUBLIC_AUTH_API;
export const SECRET_KEEPER_API = NEXT_PUBLIC_PROXY['secret-keeper'] ? '/api/proxy-secret-keeper' : NEXT_PUBLIC_SECRET_KEEPER_API;
export const SP_PROVIDER_API = NEXT_PUBLIC_PROXY['sp-provider'] ? '/api/proxy-sp-provider' : NEXT_PUBLIC_SP_PROVIDER_API;
export const PCCS_API = NEXT_PUBLIC_PROXY.pccs ? '/api/proxy-pccs' : NEXT_PUBLIC_PCCS_API;