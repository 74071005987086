import { createSelector } from '@reduxjs/toolkit';
import { BigNumber } from 'bignumber.js';
import { getFixedDeposit } from 'utils/sdk/utils';
import { Balance } from './types';
import { balanceSelector } from '.';

export const fixedBalanceSelector = createSelector(
  balanceSelector,
  ({ tee, matic }: Balance = {}) => ({
    tee: typeof tee === 'string' ? getFixedDeposit({ deposit: new BigNumber(tee) }) : '-',
    matic: typeof matic === 'string' ? getFixedDeposit({ deposit: new BigNumber(matic) }) : '-',
  }),
);

export const balanceBigNumberSelector = createSelector(
  balanceSelector,
  ({ tee, matic }: Balance = {}) => ({
    tee: typeof tee === 'string' ? new BigNumber(tee || '0') : undefined,
    matic: typeof matic === 'string' ? new BigNumber(matic || '0') : undefined,
  }),
);
