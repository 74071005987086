import {
  S3Client,
  S3ClientConfig,
  PutObjectCommandInput,
  DeleteObjectCommand,
  DeleteObjectCommandInput,
  GetObjectCommand,
  GetObjectCommandInput,
  ListObjectsV2CommandInput,
  ListObjectsCommand,
} from '@aws-sdk/client-s3';
import { Upload, Progress as ProgressAWS } from '@aws-sdk/lib-storage';
import {
  Hash,
} from '@super-protocol/dto-js';

export interface UploadFileByS3Props {
    stream: ReadableStream;
    fileName: string;
}

export interface UploadFileStreamS3 {
  clientConfig: S3ClientConfig;
  params: Omit<PutObjectCommandInput, 'Key' | 'Body'>;
  prefix: string;
}

export interface DeleteFileS3 {
  clientConfig: S3ClientConfig;
  params: Omit<DeleteObjectCommandInput, 'Key'>;
  prefix: string;
}

export interface DeleteFileProps {
  s3: DeleteFileS3;
  fileName: string;
}

export interface UploadFileS3 {
  clientConfig: S3ClientConfig;
  params: Omit<PutObjectCommandInput, 'Key'>;
  prefix: string;
}

export interface UploadFileProps {
  s3: UploadFileS3;
  fileName: string;
  abortController?: AbortController;
}

export interface Progress extends ProgressAWS {}

export interface UploadFileStreamProps {
    stream: ReadableStream;
    fileName: string;
    s3: UploadFileStreamS3;
    onProgress?: (progress: Progress) => void;
    onLoading?: (loading: boolean) => void;
    abortController?: AbortController;
}

export interface DownloadFileStreamS3 {
  clientConfig: S3ClientConfig;
  params: Omit<GetObjectCommandInput, 'Key' | 'Body'>;
  prefix: string;
}

export interface DownloadFileStreamProps {
  fileName: string;
  abortController?: AbortController;
  s3: DownloadFileStreamS3;
}

export interface UploadFileServerProps {
  stream: ReadableStream;
  fileName: string;
  getAuthTag: () => Buffer;
  getHash: () => Hash;
  fileSize: number;
}

export interface UploadFileResult {
  hash: Hash;
  mac: Buffer;
}

export interface DownloadFileS3 {
  clientConfig: S3ClientConfig;
  params: Omit<GetObjectCommandInput, 'Key'>;
  prefix: string;
}

export interface DownloadFileProps {
  fileName: string;
  abortController?: AbortController;
  s3: DownloadFileS3;
}

export interface CheckListAccessS3 {
  clientConfig: S3ClientConfig;
  params: Omit<ListObjectsV2CommandInput, 'Key'>;
  prefix: string;
}

export interface CheckListAccessProps {
  abortController?: AbortController;
  s3: CheckListAccessS3;
}

export const uploadFileStream = async (props: UploadFileStreamProps) => {
  const {
    stream, fileName, s3, onProgress, onLoading, abortController,
  } = props;

  try {
    onLoading?.(true);
    const { clientConfig, params, prefix } = s3;

    const upload = new Upload({
      client: new S3Client(clientConfig),
      params: {
        Key: `${prefix || ''}${fileName}`,
        Body: stream,
        ...params,
      },
      abortController,
    });

    upload.on('httpUploadProgress', (progress: Progress) => {
      onProgress?.(progress);
    });

    await upload.done();
  } finally {
    onLoading?.(false);
  }
};

export const downloadFileStream = async (props: DownloadFileStreamProps): Promise<ReadableStream | undefined> => {
  const {
    fileName, s3, abortController,
  } = props;
  const { clientConfig, params, prefix } = s3;
  const s3Client = new S3Client(clientConfig);

  const command = new GetObjectCommand({
    Key: `${prefix || ''}${fileName}`,
    ...params,
  });
  const response = await s3Client.send(command, {
    abortSignal: abortController?.signal,
  });
  return response.Body as ReadableStream;
};

export const deleteFile = async (props: DeleteFileProps) => {
  const { s3, fileName } = props;
  const { clientConfig, params, prefix } = s3;
  const client = new S3Client(clientConfig);
  const deleteObjectCommand = new DeleteObjectCommand({ ...params, Key: `${prefix || ''}${fileName}` });
  await client.send(deleteObjectCommand);
};

export const uploadFile = async (props: UploadFileProps) => {
  const { s3, fileName, abortController } = props;
  const { clientConfig, params, prefix } = s3;
  const upload = new Upload({
    client: new S3Client(clientConfig),
    params: {
      Key: `${prefix || ''}${fileName}`,
      ...params,
    },
    abortController,
  });
  await upload.done();
};

export const downloadFile = async (props: DownloadFileProps): Promise<ReadableStream | undefined> => {
  const {
    fileName, s3, abortController,
  } = props;
  const { clientConfig, params, prefix } = s3;
  const s3Client = new S3Client(clientConfig);

  const command = new GetObjectCommand({
    Key: `${prefix || ''}${fileName}`,
    ...params,
  });
  const response = await s3Client.send(command, {
    abortSignal: abortController?.signal,
  });
  const { Body } = response;
  if (!Body) return undefined;
  return Body as ReadableStream;
};

export const checkListAccess = async (props: CheckListAccessProps): Promise<void> => {
  const {
    s3, abortController,
  } = props;
  const { clientConfig, params, prefix } = s3;
  const s3Client = new S3Client(clientConfig);

  const command = new ListObjectsCommand({
    ...params,
    Prefix: prefix,
  });
  await s3Client.send(command, {
    abortSignal: abortController?.signal,
  });
};