import { createAsyncThunk } from '@reduxjs/toolkit';
import { isProviderSelector as isProviderOAuth2Selector, userSelector as userOAuth2Selector } from 'lib/features/authOAuth2';
import {
  userSelector as userSecretKeeperSelector,
  isProviderSelector as isProviderSecretKeeperSelector,
} from 'lib/features/secretKeeper';
import getConfig from 'config';
// // eslint-disable-next-line import/no-cycle
// import { RootState } from 'lib/store'; // todo
import { EventProperty } from './types';

export const trackEvent = createAsyncThunk<
void,
  { eventType: string, property?: string | EventProperty },
  { rejectValue: string | Error, state: any } // todo
>(
  'events/trackEvent',
  async ({ eventType, property }, { getState }) => {
    const state = getState();
    const config = getConfig();
    let user;
    if (isProviderOAuth2Selector(state)) {
      user = userOAuth2Selector(state);
    } else if (isProviderSecretKeeperSelector(state)) {
      user = userSecretKeeperSelector(state);
    }
    if (!user) return;
    const { id: userId } = user || {};
    const { deviceId } = state.system || {};
    const { Analytics, BrowserEventProvider } = await import('@super-protocol/sdk-js');
    const browserEventProvider = new BrowserEventProvider({
      userId: userId || '',
      deviceId,
      platform: 'web-ai',
    });
    const analytics = new Analytics({
      apiUrl: config.NEXT_PUBLIC_ANALYTICS_API,
      apiKey: config.NEXT_PUBLIC_ANALYTICS_KEY,
      eventProvider: browserEventProvider,
    });
    await analytics.trackEventCatched({
      eventName: eventType,
      eventProperties: property,
    });
  },
);
