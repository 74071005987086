import { createECDH } from 'crypto';
import * as bip39 from 'bip39';

export interface GenerateKeysByMnemonicResult {
    privateKeyBase64: string;
    publicKeyBase64: string;
    privateKeyBuffer: Buffer;
    publicKeyBuffer: Buffer;
    mnemonic: string;
}
export interface GenerateRandomKeysResult {
    privateKeyBuffer: Buffer;
    publicKeyBuffer: Buffer;
    privateKeyBase64: string;
    publicKeyBase64: string;
}

export class CryptoUtils {
  public static readonly MAX_LENGTH_BASE64_MNEMONIC = 44; //if key size 256
  public static async generateRandomKeys(): Promise<GenerateRandomKeysResult> {
    const ecdh = createECDH('secp256k1');
    ecdh.generateKeys();
    const privateKeyBuffer = ecdh.getPrivateKey();
    const publicKeyBuffer = ecdh.getPublicKey();
    const privateKeyBase64 = privateKeyBuffer.toString('base64');
    const publicKeyBase64 = publicKeyBuffer.toString('base64');
    return {
      privateKeyBuffer,
      publicKeyBuffer,
      privateKeyBase64,
      publicKeyBase64,
    };
  }

  public static async getPublicFromPrivate(privateKeyBase64: string): Promise<string> {
    const privateKeyBuffer = Buffer.from(privateKeyBase64, 'base64');
    const ecdh = createECDH('secp256k1');
    ecdh.setPrivateKey(privateKeyBuffer);
    const publicKeyBuffer = ecdh.getPublicKey();
    return publicKeyBuffer.toString('base64');
  }

  public static async validateMnemonic(mnemonic: string): Promise<boolean> {
    if (!mnemonic) return false;
    let validate = bip39.validateMnemonic(mnemonic);
    if (!validate) return validate;
    try {
      await CryptoUtils.generateKeysByMnemonic(mnemonic);
    } catch (e) {
      validate = false;
    }
    return validate;
  }

  public static async generateKeysByMnemonic(mnemonic: string): Promise<GenerateKeysByMnemonicResult> {
    const entropy = bip39.mnemonicToEntropy(mnemonic);
    const privateKeyBuffer = Buffer.from(entropy, 'hex');

    const ecdh = createECDH('secp256k1');
    ecdh.setPrivateKey(privateKeyBuffer);

    const publicKeyBuffer = ecdh.getPublicKey();
    const privateKeyBase64 = privateKeyBuffer.toString('base64');
    const publicKeyBase64 = publicKeyBuffer.toString('base64');

    return {
      privateKeyBase64,
      publicKeyBase64,
      privateKeyBuffer,
      publicKeyBuffer,
      mnemonic,
    };
  }

  public static generateMnemonic(): string {
    return bip39.generateMnemonic(256);
  }
}