import { useCallback, useMemo } from 'react';
import { useAppSelector, useAppDispatch } from 'lib/hooks';
import {
  api,
} from 'lib/features/spProvider/api';
import {
  resetState,
} from 'lib/features/spProvider';
import { useAuthOAuth2, UseAuthOAuth2 } from './useAuthOAuth2';

export interface UseAuthSPProvider extends UseAuthOAuth2 {}

export const useAuthSPProvider = () => {
  const dispatch = useAppDispatch();
  const { logout: logoutAuthOAuth2, ...authOAuth2 } = useAuthOAuth2();
  const getUserSettingsState = useMemo(() => api.endpoints.getUserSettings.select(null), []);
  const getUserSettingsResponse = useAppSelector(getUserSettingsState);
  const logout = useCallback(async () => {
    await logoutAuthOAuth2();
    dispatch(resetState());
  }, [logoutAuthOAuth2, dispatch]);

  return {
    ...authOAuth2,
    loading: authOAuth2.loading || getUserSettingsResponse.isLoading,
    logout,
  };
};