import { useCallback } from 'react';

import { useAppDispatch } from 'lib/hooks';
import { addErrorNotification, addSuccessNotification } from 'lib/features/notifications';

export const useNotification = () => {
  const dispatch = useAppDispatch();

  const showError = useCallback((error: Error, defaultMessage = 'Something went wrong') => {
    dispatch(addErrorNotification(error?.message || defaultMessage));
  }, [dispatch]);

  const showSuccess = useCallback((message = 'success') => {
    dispatch(addSuccessNotification(message));
  }, [dispatch]);

  return {
    showError,
    showSuccess,
  };
};